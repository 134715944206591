<template>
  <div class="training-item">
    <div class="row">
      <div class="col-sm-8">
        <b-card-text>
          <b-card-title class=" mb-1">
            {{ training.course_name }}

          </b-card-title>
          {{ isExpired || isCompleted ? expiryDate : `Estimated completion time: ${estimated}` }}
        </b-card-text>
        <b-row
          v-if="isAvailable">
          <!-- Progress bar column, taking most of the width -->
          <b-col sm="10" xl="10">
            <b-progress :value="isCompleted ? 1 : activeParticipantPercentage" :max="max" :class="isCompleted ? 'default-green-chart-color' :
              'default-chart-color'" />
          </b-col>

          <!-- Percentage column, aligned to the right -->
          <b-col sm="2" xl="2">
            {{ isCompleted ? '100' : activeParticipantPercentage * 100 > 100 && !isCompleted ? '99' :
              activeParticipantPercentage * 100 }}%
          </b-col>
        </b-row>
      </div>
      <div class="col-sm-4 d-flex flex-column ">
        <div 
        v-if="isAvailable"
        class="d-flex flex-column">
          <b-button :disabled="isLaunching || isCompleted || isExpired" :variant="variantColour"
            v-b-tooltip.hover.top="'Please disable pop-up blockers to ensure the program opens properly in a new window.'"
            class="d-flex justify-content-center align-items-center" style="min-width: 155px; min-height:48px;"
            @click="launchProgramTraining(training.id)">
            <b-spinner v-if="isLaunching" small />
            <img v-if="isCompleted || isExpired" :src="isCompleted ? completedIcon : expiredIcon" class="mr-1" />
            <span>{{ buttonCopy }}</span>
          </b-button>
          <!-- Time taken column, placed under the progress bar row -->
          <b-col class="d-flex mt-1 flex-column  align-items-center">
            <span class=""> Time taken: </span><span class="font-small-3">{{ spent }}</span>
          </b-col>
        </div>
        <div v-else
          class="d-flex flex-column">
          <!-- Time taken column, placed under the progress bar row -->
          <b-col class="d-flex mt-1 flex-column  align-items-center">
            <span class="">Available on  {{ availabilityDate }}</span>
          </b-col>
        </div>  
      </div>
    </div>

    <b-row>
      <!-- Parent column that holds the progress bar and percentage, taking full width -->
      <b-col sm="8" lg="8">

      </b-col>


    </b-row>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BAvatar,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCard,
  BSpinner,
  BCardTitle,
  BDropdownDivider,
  BProgress,
  BBadge,
  VBTooltip
} from "bootstrap-vue";
import Trophy from "@/assets/images/icons/trophy.svg";
import Expired from "@/assets/images/icons/expired.svg";
import trainingsService from "@/services/trainingsService";
import { programTypes, trainingStatus } from "@/models";
import { makeSuccessToast, makeErrorToast, localeDateStringFromIsoDateTime, AOM_GREEN_COLOR } from '@/libs/utils';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    BButton,
    BCardText,
    BCard,
    BSpinner,
    BCardTitle,
    BDropdownDivider,
    BProgress,
    BBadge,
    FeatherIcon,
  },
  props: {
    training: {
      type: Object,
      default: () => { },
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      completedIcon: Trophy,
      expiredIcon: Expired,
      value: 0,
      max: 1,
      rows: [],
      isLaunching: false,
      isUpdating: false,
      defaultChartColor: AOM_GREEN_COLOR,
      defaultGreenChartColor: AOM_GREEN_COLOR,
    };
  },
  computed: {
    ...mapGetters("participants", [
      "program",
      "mentorDisplay",
      "menteeDisplay",
    ]),
    variantColour() {
      if (this.isCompleted) {
        return 'success'
      }
      if (this.isExpired) {
        return 'dark'
      }
      return 'primary'

    },
    availabilityDate() {
      return localeDateStringFromIsoDateTime(this.training.scheduled_action.absolute_date);
    },
    buttonCopy() {
      if (this.isCompleted) {
        return "Completed";
      }
      if (this.isExpired) {
        return "Expired";
      }
      return "Launch";
    },
    activeParticipantPercentageChartOption() {
      if (this.activeParticipantPercentage[0] > 50) {
        return { ...this.defaultChartOptions, colors: [this.defaultGreenChartColor] };
      }

      return { ...this.defaultChartOptions, colors: [this.defaultChartColor] };
    },
    activeParticipantPercentage() {
      const spentTime = this.trainingDetail?.total_seconds_tracked || 0;
      const estimatedTime = this.training?.estimated_seconds || 0;
      if (spentTime === 0) {
        return 0;
      }
      return (spentTime / estimatedTime).toFixed(2);
    },
    trainingDetail() {
      if (!this.training || !this.training.program_training_users) {
        return null;
      }
      return this.training.program_training_users[
        this.training.program_training_users.length - 1
      ];
    },


    isProgramTraining() {
      return this.program?.type_id === programTypes.TRAINING;
    },

    estimated() {

      if (!this.training) {
        return 'Not started';
      }
      const estimatedTime = this.training.estimated_seconds || 0;

      if (estimatedTime === 0) {
        return '0 minute';
      }

      // Don't show if seconds less than 60
      if (estimatedTime < 60) {
        return '0 minute';
      }

      return `${Math.round(estimatedTime / 60)} minutes`;
    },
    isAvailable() {
      if (!this.training) {
        return false;
      }
      return  new Date(this.training.scheduled_action.absolute_date) < new Date()
    },
    spent() {
      if (!this.trainingDetail) {
        return 'Not started';
      }

      const spentTime = this.trainingDetail.total_seconds_tracked || 0;
      if (spentTime === 0) {
        return '0 minute';
      }

      // Don't show if seconds less than 60
      if (spentTime < 60) {
        return '0 minute';
      }

      return `${Math.round(spentTime / 60)} minutes`;
    },

    isCompleted() {
      if (!this.trainingDetail) {
        return false;
      }
      if (!this.trainingDetail.status) {
        return false;
      }
      return this.trainingDetail.status.id === trainingStatus.COMPLETE;
    },
    expiryDate() {
      return `${this.isExpired ? 'Expired ' : 'Expires'} on ${localeDateStringFromIsoDateTime(this.training.available_end_date)}`;
    },
    isExpired() {
      if (!this.training.available_end_date) {
        return false;
      }
     
      return new Date(this.training.available_end_date) < new Date();
    },
  },
  methods: {
    async launchProgramTraining(trainingId) {
      this.isLaunching = true;
      try {
        const response = await trainingsService.launchProgramTraining(
          this.program.id,
          trainingId
        );
        if (response && response.data) {
          window.open(response.data.launch_link);
        }
      } catch (e) {
        const { status, data } = e.response;
        if (status === 404 || status === 400 || status === 500) {
          this.$toast(makeErrorToast(data.message));
        } else {
          this.$toast(makeErrorToast("Something went wrong. Please try again later."));
        }
        this.$log.error(e);
      } finally {
        this.isLaunching = false;
      }
    },

    async markProgramTrainingComplete(trainingId) {
      this.isUpdating = true;
      try {
        const response = await trainingsService.markProgramTrainingsComplete(
          this.program.id,
          { trainings: [{ id: trainingId }] }
        );
        if (response && response.data) {
          this.$toast(makeSuccessToast("Training has been successfully marked as completed."));
          this.$emit('completed');
        }
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong. Please try again later."));
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },

    formatDate(datetime) {
      if (datetime) {
        return localeDateStringFromIsoDateTime(datetime, true);
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.training-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;
}
</style>